import NextLink from 'next/link'

import {
  Box,
  Heading,
  Flex,
  Link as ChakraLink,
  Text,
  Button
} from '@chakra-ui/core'

const PostPreview = ({ title, link, description, cover, isHome }) => {
  return (
    <Flex
      backgroundColor={isHome ? '#FFFFFF' : '#F6F8FE'}
      boxShadow={isHome ? '0px 4px 25px rgba(0,0,0,0.03)' : 'none'}
      minHeight={['initial', '300px', '428px', '428px']}
      padding={['20px', '30px', '40px', '40px']}
      borderRadius='6px'
      mb='35px'
      direction={['column', 'row', 'row', 'row']}
    >
      <Box width={['100%', '45%', '45%', '45%']} mr={['0%', '5%', '5%', '5%']}>
        <NextLink href={link}>
          <ChakraLink>
            <Heading
              fontSize={['2rem', '2.5rem', '2.75rem', '3rem']}
              textAlign='left'
              lineHeight='118%'
              as='h2'
              mb='16px'
            >
              {title}
            </Heading>
          </ChakraLink>
        </NextLink>
        <Text
          fontSize='1.125rem'
          fontWeight='400'
          textAlign='left'
          lineHeight='140%'
        >
          {description}
        </Text>
        <NextLink href={link}>
          <ChakraLink>
            <Button
              height='40px'
              background='#201E2F'
              color='white'
              mt='24px'
              type='submit'
              borderRadius='5px'
              fontWeight='bold'
              justifyContent='center'
              alignItems='center'
              fontSize='0.875rem'
              _hover={{
                background: '#3A374E'
              }}
              display={['none', 'inline-block', 'inline-block', 'inline-block']}
            >
              Read more
            </Button>
          </ChakraLink>
        </NextLink>
      </Box>
      {cover && (
        <NextLink href={link}>
          <ChakraLink display='flex' width={['100%', '50%', '50%', '50%']}>
            <Box
              width='100%'
              backgroundPosition='center'
              mt={['20px', 'initial', 'initial', 'initial']}
              height={['280px', 'initial', 'initial', 'initial']}
              backgroundSize='cover !important'
              backgroundImage={`url(${cover})`}
            />
          </ChakraLink>
        </NextLink>
      )}
      {!cover && (
        <Box
          width={['100%', '50%', '50%', '50%']}
          background='white'
          height='100%'
        />
      )}
    </Flex>
  )
}

export default PostPreview
