import PostPreview from './post-preview'

function importAll (r) {
  return r.keys().map(r)
}

const previewItems = importAll(
  require.context('../../pages/blog', false, /^\.\/(.*)\.mdx$/)
)

const Items = ({ isHome }) => {
  return (
    <div>
      {previewItems
        .sort((a, z) => a.meta.order - z.meta.order)
        .filter((post) => {
          if (isHome === true) {
            return post.meta.showOnHomePage
          } else {
            return true
          }
        }
        )
        .map(({ default: Component, meta }, index) => {
          return (
            <PostPreview
              isHome={isHome}
              key={meta.title}
              detail={index < 5}
              {...meta}
            >
              <Component {...meta} />
            </PostPreview>
          )
        })}
    </div>
  )
}

export default Items
