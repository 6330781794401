
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import withPost from "../../components/blog/with-post.jsx"
import Video from "../../components/blog/video.jsx"
import { LinkAttachmentDisplay } from "../../components/event-attachments/link.js"
import { TextAttachmentDisplay } from "../../components/event-attachments/text.js"
import { Flex } from "@chakra-ui/core"
export const meta = {
  title: "Makers, draw more attention to your events!",
  description: `Attach links to your events and add text descriptions to them. 
Attract more people by adding beautiful backgrounds. 
`,
  link: "/blog/dashboard-attachments-backgrounds",
  cover: "/static/blog/post20.jpg",
  order: 3,
  showOnHomePage: false
};

const layoutProps = {
  meta
};
const MDXLayout = withPost({ ...meta })
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Makers, draw more attention to your events!`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` This post was written while working on Hapen 1.0, so most of what we are talking about here is no longer relevant. `}<a parentName="p" {...{
          "href": "/blog/2.0"
        }}>{`Read about Hapen 2.0.`}</a></p>
    </blockquote>
    <p>{`Today's update brings a lot of useful features for makers!
Attach links to your events and add text descriptions to them.
Edit your events and attract more people by adding beautiful backgrounds.
We also got a dashboard!`}</p>
    <h2>{`Dashboard 🔑`}</h2>
    <p>{`This is the foundation for all our future features.
This allows you to quickly create and track events created by you.
For more convenience, events are divided into “upcoming" and "past" tabs.
You can also quickly go to the event page by clicking on the event card.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/blog/dashboard-demo.png",
        "alt": "User Dashboard"
      }}></img></p>
    <p>{`You can log in with one click using Apple or Google. No usernames and passwords.`}</p>
    <h2>{`Event customization 🖌`}</h2>
    <p>{`Events created in the dashboard are now editable!
Now you can change the name, Emoji, date and time of the event.
You can also change the background and add attachments (more on this below).`}</p>
    <Video loop autoPlay src="/static/blog/event-editing.mp4" mdxType="Video" />
    <h2>{`Introducing Attachments 💫`}</h2>
    <p>{`Meet the attachments!
These are useful blocks that you can add to your event page.
They give your pages new functionality,
and there will be more and more of them in the future 😊`}</p>
    <p>{`Today we present you two new ones: `}<strong parentName="p">{`Text 💬`}</strong>{` and `}<strong parentName="p">{`Link 🔗.`}</strong></p>
    <h3>{`Link Attachment 🔗`}</h3>
    <p>{`Link attachment allows you to pin a link to a website on the event page.
Just redirect people to the resource you need.
This will be especially useful for people who use Hapen to plan live streams and webinars.
Now users can go to the streaming platform directly from the event page.`}</p>
    <p>{`It will look like this:`}</p>
    <Flex justify="center" alignItems="center" mdxType="Flex">
  <LinkAttachmentDisplay title="John's Cooking Livestream" description="Join John Dow and cook delicious spaghetti with him! Get free recipes to your email by subscribing to newsletter on spaghetti-john-dow.example.com. Livestream starts November 16 at 9:00 PM." url="https://john-cooking-live.example.com" imageUrl="/static/blog/spaghetti.jpg" wrapperProps={{
        width: ["100%", "70%", "70%", "70%"]
      }} mdxType="LinkAttachmentDisplay" />
    </Flex>
    <p>{`In addition, it will be useful for people who are planning to release their content/product at some point in time.
For example, you are launching your new product and want more people to know about this event.
Add a link attachment to your site, and interested people can go to your site directly from the event page.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/blog/meditation-demo.png",
        "alt": "Link Attachment Demo"
      }}></img></p>
    <h3>{`Text Attachment 💬`}</h3>
    <p>{`Text attachment allows you to attach a small text description to an event that is 240 characters long.`}</p>
    <p>{`This is especially useful when you need to tell people something about an upcoming event.
For example, your group chat plans to make a joint call in discord.
After creating an event page, you can add a text attachment with
the topics you are going to discuss or any other information.`}</p>
    <Flex justify="center" alignItems="center" mdxType="Flex">
  <TextAttachmentDisplay wrapperProps={{
        width: ["100%", "70%", "70%", "70%"]
      }} text="Guys, let's get to know each other better on this call. Each of us will introduce ourselves and tell each other about them and their projects. It will be fun!" mdxType="TextAttachmentDisplay" />
    </Flex>
    <h2>{`Event Backgrounds 🖼`}</h2>
    <p>{`Earlier, `}<a parentName="p" {...{
        "href": "https://hapen.io/batman-most-wanted-2020"
      }}>{`we showcased`}</a>{` a feature that allows you to
add backgrounds to the event page. From now on, you can add a beautiful background
to any event created in dashboard!`}</p>
    <p>{`While editing an event, you can upload the background from your device,
or pick up any image from Unsplash. We will be working on new ways to upload backgrounds 😸`}</p>
    <p><img parentName="p" {...{
        "src": "/static/blog/batman-background-demo.png",
        "alt": "Batman Most Wanted Event Demo"
      }}></img></p>
    <p>{`This can increase attention to your event and set the right mood. The background image is also used in the preview of the link to your event (opengraph tags).`}</p>
    <h2>{`Newsletter 💌`}</h2>
    <p>{`We have launched a newsletter! Subscribe to get ready-made recipes for using Hapen
in your business. We will also send you information about upcoming features
and released updates.`}</p>
    <iframe src="https://hapen.substack.com/embed" width="100%" height="100" style={{
      border: "none",
      background: "tranparent"
    }} frameBorder="0" scrolling="no" />
    <h3>{`What's next?`}</h3>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/coming"
      }}>{`upcoming features page`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;