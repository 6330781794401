
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import withPost from "../../components/blog/with-post.jsx"
export const meta = {
  title: "About Hapen",
  description: `Why event sharing is complicated and 
how we solve this with event pages.
How to avoid forcing your audience to a use specific calendar app when sharing some event?`,
  link: "/blog/about",
  cover: "/post1.jpg",
  order: 5
};

const layoutProps = {
  meta
};
const MDXLayout = withPost({ ...meta })
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About Hapen`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` This post was written while working on Hapen 1.0, so most of what we are talking about here is no longer relevant. `}<a parentName="p" {...{
          "href": "/blog/2.0"
        }}>{`Read about Hapen 2.0.`}</a></p>
    </blockquote>
    <p>{`Event sharing is complicated.`}</p>
    <p>{`Most calendar apps offer various ways to share events, from links to iCal files.`}</p>
    <p>{`Social networks suggest using "event pages".`}</p>
    <p>{`But what if you don't want to deal with them just to share an event via link?`}</p>
    <p>{`Or what if the person you share an event with from Google Calendar just doesn't use it?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Often, if I'm planning events with a group of friends or professionally
it can be challenging to come up with the easiest means of keeping all the
information in one place, and what was perhaps a simple event, to begin with descends
into a lot of admin trying to create calendar invites, perhaps a Facebook event and more. — `}<a parentName="p" {...{
          "href": "https://www.producthunt.com/@calum"
        }}>{`Calum Webb, Community & Social at Product Hunt`}</a></p>
    </blockquote>
    <p><strong parentName="p">{`That's why we created Hapen.`}</strong></p>
    <p>{`We needed a way to share events with people quickly. And without too much hassle.`}</p>
    <h3>{`Shareable reminders`}</h3>
    <p><img parentName="p" {...{
        "src": "/post1.jpg",
        "alt": "title"
      }}></img></p>
    <p>{`Imagine that an event in your life has a link on the Internet.
This event can be your birthday, the day of the release of the movie that you are so much looking forward to,
your planned call with friends in Discord, or something else.`}</p>
    <p>{`In the end, It can be anything!`}</p>
    <p>{`Now imagine that `}<strong parentName="p">{`anyone can subscribe to a notification that will be sent when this event occurs.`}</strong></p>
    <p>{`This opens up new possibilities.`}</p>
    <p>{`For example, you are the author of a podcast and you have prepared new content for a certain date.
Instead of tweeting something like:`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Hey guys, I have a new podcast coming out here on October 28!`}</em></p>
    </blockquote>
    <p>{`you can create a reminder link for your subscribers.
This way you `}<em parentName="p">{`don't just tell the release date, but also give them the opportunity to subscribe to this event.`}</em>{`
This increases the number of listenings, as people who subscribe will receive a notification about new podcast episode.`}</p>
    <p><strong parentName="p">{`And this is just one of `}<a parentName="strong" {...{
          "href": "/#use-cases"
        }}>{`many use cases`}</a>{`! 😉`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`Right now, you can create an event without signup from our `}<a parentName="p" {...{
          "href": "/"
        }}>{`main page`}</a>{`. We are working on
a personal dashboard: track your events, customize the event background, add attachments (like a link to your website), and more.`}</p>
    </blockquote>
    <p>{`At this stage the event consists of:`}</p>
    <ul>
      <li parentName="ul">{`Event name`}</li>
      <li parentName="ul">{`Emoji`}</li>
      <li parentName="ul">{`Trigger date`}</li>
    </ul>
    <p>{`Emojis allow your events to stand out. You can use them any way you want 🤩`}</p>
    <p><strong parentName="p">{`The most interesting thing is `}<em parentName="strong">{`how`}</em>{` people get notifications about your event.`}</strong></p>
    <h3>{`Zero lock-in`}</h3>
    <p><img parentName="p" {...{
        "src": "/post2.png",
        "alt": "title"
      }}></img></p>
    <p>{`Imagine if you created an event called something like `}<strong parentName="p">{`"A new Batman movie is coming out"`}</strong>{` and started distributing this event as an iCal file.
How many people would add this to their calendar?`}</p>
    <p>{`First, it binds everyone interested in using iCalendar.`}</p>
    <p>{`Secondly, it is inconvenient 😅`}</p>
    <p>{`We do not force the user to use specific calendar app.`}</p>
    <p><strong parentName="p">{`Thanks to our integrations, users can get notified wherever they `}<em parentName="strong">{`prefer`}</em></strong>{`.`}</p>
    <p>{`Your subscribers can add an event to their calendar, todo-app, or receive a text message in their favorite messenger.
It's up to them.`}</p>
    <p>{`This allows you to increase the number of people interested in the event.`}</p>
    <p>{`This is why we are actively working on new integrations that allow people to choose their preferred method of receiving notifications when an event occurs.`}</p>
    <p>{`At the moment we provide the following methods:`}</p>
    <ul>
      <li parentName="ul">{`Web Push (Chrome & MacOS Safari)`}</li>
      <li parentName="ul">{`Telegram Messenger`}</li>
      <li parentName="ul">{`Google Calendar`}</li>
      <li parentName="ul">{`iCal`}</li>
      <li parentName="ul">{`Yahoo Calendar`}</li>
      <li parentName="ul">{`Outlook Calendar`}</li>
    </ul>
    <p>{`Thank you for your time, we continue to actively work on the new!`}</p>
    <h2>{`Newsletter 💌`}</h2>
    <p>{`We have launched a newsletter! Subscribe to get ready-made recipes for using Hapen
in your business. We will also send you information about upcoming features
and released updates.`}</p>
    <iframe src="https://hapen.substack.com/embed" width="100%" height="100" style={{
      border: "none",
      background: "tranparent"
    }} frameBorder="0" scrolling="no" />
    <h3>{`What's next?`}</h3>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/coming"
      }}>{`upcoming features page`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;