
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import withPost from "../../components/blog/with-post.jsx"
import { Flex } from "@chakra-ui/core"
export const meta = {
  title: "Event Alerts",
  description: `Schedule meetings, conference calls, and offline events`,
  link: "/blog/alerts",
  cover: "/static/blog/post3.jpg",
  order: 4,
  showOnHomePage: false
};

const layoutProps = {
  meta
};
const MDXLayout = withPost({ ...meta })
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Event Alerts - schedule meetings, conference calls, and offline events`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` This post was written while working on Hapen 1.0, so most of what we are talking about here is no longer relevant. `}<a parentName="p" {...{
          "href": "/blog/2.0"
        }}>{`Read about Hapen 2.0.`}</a></p>
    </blockquote>
    <p>{`Today's little update brings Event Alerts.
This allows your event followers to remind themselves of the event before it starts.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/blog/post3_example.png",
        "alt": "Event Alerts"
      }}></img></p>
    <h2>{`How it works? ⏲`}</h2>
    <p>{`Before, event notifications on Hapen were sent immediately after the event started.
This made it impossible to plan any offline or virtual events - people may not be ready to start.`}</p>
    <p>{`A common situation is when you need to organize a group call. And so that no one will forget about it 🙄`}</p>
    <p>{`Now, participation in events has become more convenient. Set an alert: 10 minutes, an hour, a day before the event starts.`}</p>
    <h2>{`How to use this feature? 🗓`}</h2>
    <ul>
      <li parentName="ul">{`Go to `}<a parentName="li" {...{
          "href": "https://hapen.io/dashboard"
        }}>{`Dashboard`}</a>{` and create an event.`}</li>
      <li parentName="ul">{`Share the created event with a button at the right top corner.`}</li>
      <li parentName="ul">{`Users can select the desired alert option while setting the reminder.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/static/blog/post3_interview.png",
        "alt": "Job Interview"
      }}></img></p>
    <h2>{`Other improvements 💫`}</h2>
    <ul>
      <li parentName="ul">{`"Continue with Facebook" button on `}<a parentName="li" {...{
          "href": "https://hapen.io/sign-in"
        }}>{`sign-in`}</a>{` page`}</li>
      <li parentName="ul">{`Share button on event page 😊`}</li>
    </ul>
    <h2>{`Newsletter 💌`}</h2>
    <p>{`We have launched a newsletter! Subscribe to get ready-made recipes for using Hapen
in your business. We will also send you information about upcoming features
and released updates.`}</p>
    <iframe src="https://hapen.substack.com/embed" width="100%" height="100" style={{
      border: "none",
      background: "tranparent"
    }} frameBorder="0" scrolling="no" />
    <h3>{`What's next?`}</h3>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/coming"
      }}>{`upcoming features page`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;