
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import withPost from "../../components/blog/with-post.jsx"
import { Flex } from "@chakra-ui/core"
export const meta = {
  title: "Hapen 2.0",
  description: `We are thrilled to announce Hapen's reborn into the next-generation meeting management solution. This transformation has brought with it new features and capabilities that will upgrade the way you schedule and manage your meetings.`,
  link: "/blog/2.0",
  cover: "/static/About_Notice.png",
  order: 0,
  showOnHomePage: true
};

const layoutProps = {
  meta
};
const MDXLayout = withPost({ ...meta })
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hapen 2.0`}</h1>
    <p>{`We are thrilled to announce Hapen's reborn into the `}<strong parentName="p">{`next-generation meeting management solution.`}</strong>{`
This transformation has brought with it new features and capabilities
that will upgrade the way you schedule and manage your meetings.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TL;DR`}</strong>{`
Use Hapen as your meeting management tool, let your attendees use messengers instead of email
and keep your meeting context in one place with notes right on the event page!`}</p>
    </blockquote>
    <p><strong parentName="p">{`So what's new? Let's get into the new features!`}</strong></p>
    <h2>{`Choose your availability`}</h2>
    <p>{`First of all, set the days and time ranges you are available for meetings on the `}<a parentName="p" {...{
        "href": "/settings"
      }}>{`User Settings`}</a>{` page.
After that, you will be ready to receive appointments only for those time slots when it is convenient for you.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/availability.jpg",
        "alt": "Availability"
      }}></img></p>
    <h2>{`Create & Share Meeting Pages`}</h2>
    <p>{`What kind of meetings do you usually hold?
Maybe 30-minute sessions to showcase your company or product,
or hour-long sessions to solve a specific problem?`}</p>
    <p>{`Create an individual page for each type of meeting you have on `}<a parentName="p" {...{
        "href": "/event-types"
      }}>{`Meeting Pages.`}</a>{`
In a Meeting Page settings, you can set rules such as meeting location and duration. `}</p>
    <p><img parentName="p" {...{
        "src": "/static/type.jpg",
        "alt": "Event type"
      }}></img></p>
    <p>{`Now you are ready to share your scheduling page with the world!
You can either share the link to your Meeting Page or embed it on your website.`}</p>
    <h2>{`Untie your attendees from using email`}</h2>
    <p>{`One of the most significant changes we have made is the integration of
popular messaging platforms such as Messenger, Telegram, and Microsoft Teams.`}</p>
    <p>{`Your attendees can book an appointment with the messenger that is convenient for them, email is not required.
They will receive updates about the meeting in the messenger of their choice.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/booked.jpg",
        "alt": "Booked"
      }}></img></p>
    <p>{`The good old email is still available.`}</p>
    <h2>{`Keep your meeting context in one place`}</h2>
    <p><strong parentName="p">{`What usually happens after hanging up?`}</strong></p>
    <p>{`Often the entire context of the meeting either remains somewhere in email threads or messengers or nowhere. `}</p>
    <p>{`Add notes about the meeting on the Meeting Page right during the meeting so as not to lose important details of the conversation.
Important details from the meeting will stay easily accessible and retrievable.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`We are confident that Hapen will revolutionize the way you schedule and manage your meetings.
Its user-friendly interface, combined with its advanced features, makes it
the perfect solution for all your meeting needs.`}</p>
    <h2>{`Newsletter 💌`}</h2>
    <p>{`Subscribe to get ready-made recipes for using Hapen
in your business. We will also send you information about upcoming features
and released updates.`}</p>
    <iframe src="https://hapen.substack.com/embed" width="100%" height="100" style={{
      border: "none",
      background: "tranparent"
    }} frameBorder="0" scrolling="no" />
    <h3>{`What's next?`}</h3>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/coming"
      }}>{`upcoming features page`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;