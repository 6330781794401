
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import withPost from "../../components/blog/with-post.jsx"
import { Flex } from "@chakra-ui/core"
export const meta = {
  title: "Facebook Messenger Integration",
  description: `Now your subscribers can receive notifications about your events directly in Facebook Messenger!`,
  link: "/blog/facebook-messenger",
  cover: "/static/blog/messenger-demo-cropped.png",
  order: 2,
  showOnHomePage: false
};

const layoutProps = {
  meta
};
const MDXLayout = withPost({ ...meta })
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Facebook Messenger Integration`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` This post was written while working on Hapen 1.0, so most of what we are talking about here is no longer relevant. `}<a parentName="p" {...{
          "href": "/blog/2.0"
        }}>{`Read about Hapen 2.0.`}</a></p>
    </blockquote>
    <p>{`Today's update brings integration with `}<a parentName="p" {...{
        "href": "https://messenger.com"
      }}>{`Facebook Messenger`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/blog/messenger-demo.png",
        "alt": "Facebook Messenger Demo"
      }}></img></p>
    <p>{`Now your subscribers can receive notifications about your events directly in Facebook Messenger!
We have created a bot that will send notifications.`}</p>
    <h2>{`How it works? ⏲`}</h2>
    <ul>
      <li parentName="ul">{`Choose "Messenger" notification method on the event page`}</li>
      <li parentName="ul">{`Click "Set reminder"`}</li>
      <li parentName="ul">{`Page will redirect you to Hapen Bot and your reminder is automatically set! `}</li>
    </ul>
    <p>{`And this is a small thought about using bots on the Messenger platform:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Every day it’s becoming harder to reach your audience.
People open less email and social media is so noisy your organic reach is often less than 10% of your audience.
Facebook Messenger bots solve this problem by providing personalized and automated conversations with your customers.
It is real-time, interactive, and has 80% open rates. — Manychat`}</p>
    </blockquote>
    <p>{`You can use this to attract a larger audience to your event.
This simplifies things. Setting a reminder in your favorite messenger
is much faster than saving an event in your calendar. This also applies to your audience.`}</p>
    <h2>{`Newsletter 💌`}</h2>
    <p>{`We have launched a newsletter! Subscribe to get ready-made recipes for using Hapen
in your business. We will also send you information about upcoming features
and released updates.`}</p>
    <iframe src="https://hapen.substack.com/embed" width="100%" height="100" style={{
      border: "none",
      background: "tranparent"
    }} frameBorder="0" scrolling="no" />
    <h3>{`What's next?`}</h3>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/coming"
      }}>{`upcoming features page`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;